import React, {useState,useEffect} from "react";
import { Link, graphql, useStaticQuery } from 'gatsby';
import { connect } from "react-redux";
import { useLocation } from "@reach/router";

// Helpers
import { getMessageWhatsapp } from '../../helpers/helper.rendering'
import { group_Unities } from '../../helpers/helper.developments'
import { findEnvironments } from '../../helpers/helper.unities'

// Components
import Formulario from '../Contact.jsx'
import Loading from '../../components/Loading'
import $ from 'jquery' // important: case sensitive.


const Units = (props) => {

    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            global_email
            global_phone
            branch_office{
                name
              contact {
                phone
                mail
                whatsapp
              }
            }
        }
  }`)
    const location = useLocation()

    const [group,setGroup] = useState([])
    const [load,setLoad] = useState(false)
    const [buttonsFilters,setButtonsFilters] = useState([])

    const {unities} = props
    const {dispatch} = props
    const {development} = props
    const {loading} = props

    const [filters,setFilters] = useState(0)
    const [isArrowClicked, setIsArrowClicked] = useState(false)

    const query = new URLSearchParams(location.search);
    const idUnidades = query.get('unidades')?.split("-") // Obtengo IDS por URL

    const [booleanScroll,setBooleanScroll] = useState(false)

    useEffect(() => {
        if(unities){
            setButtonsFilters(findEnvironments(unities))
        }
    },[unities])

    useEffect(() => {
        if(unities){
            setGroup(group_Unities(unities,filters,idUnidades))
            setLoad(true)
        }
    },[unities,filters])

    useEffect(() => {
        if(idUnidades?.length > 0 && !booleanScroll && !loading){
            if($("#unidades")){
                $('html, body').animate({
                    scrollTop: $("#unidades").offset()?.top
                },
                500);
                setBooleanScroll(true)
            }
        }
    },[idUnidades,loading])

    const updateFilter = (value) => {
        if(value===filters){return setFilters(0)}
        return setFilters(value)
    }

    return !loading ? (
        <section class="ficha-units px-lg-5 pt-1" id="unidades">
            <div class="container-fluid">
                {( group.length > 0 || filters > 0 || idUnidades?.length > 0 ? 
                <>
                    <div class="row pt-5 pb-4">
                        <div class="col-12">
                            <h4 class="mt-5">Unidades</h4>
                        </div>
                        {/* <div class="col-lg-10">
                            <p>Gran variedad de unidades que se adaptan <br class="d-none d-lg-block" /> a todas tus necesidades y requerimientos de búsqueda.</p>
                        </div> */}
                        <div className="col-12">
                            <div className="d-flex flex-wrap justify-content-center filter-btn-wrapper">
                                {
                                    buttonsFilters.length > 1 && <div className={"tag-filter proyect-bg me-3 mb-3 " + (filters === 0 ? ' active ' : '')} onClick={() => updateFilter(0)}> Todos</div>
                                }
                                {
                                    buttonsFilters.length > 1
                                    && buttonsFilters.map(element => (
                                        <div className={"tag-filter proyect-bg me-3 mb-3 " + (findEnvironments(unities.objects,element) ? '' : ' d-none ' ) + (filters === element ? ' active ' : '')} onClick={() => updateFilter(element)}>{element} Ambiente{element > 1 && 's'}</div>
                                    ))
                                } 
                            </div>
                        </div>
                    </div>  
                    <div class="row">
                        <div class="col-12">
                            {group && load ? 
                                group.map((item,index) => (
                                    <div className={"unity collapsed"} data-bs-toggle="collapse" onClick={() => setIsArrowClicked(!isArrowClicked)} data-bs-target={"#collapseExample"+index} aria-expanded="false" aria-controls={"collapseExample"+index} key={index} style={{zIndex:"5"}}>
                                        {/* <div class="row unit-table text-center no-gutters justify-content-lg-between">
                                            <p class="col-lg-2 col-4 ps-0 pe-lg-2 pe-xl-0 text-center">Unidad</p>
                                            <p class="col-lg-1 col-3">Ambientes</p>
                                            <div class="col-lg-8 col-3"></div>
                                            <p class="col-lg-1 col-2"></p>
                                        </div> */}
                                        <div className="head-row row">
                                            <div className="col-lg-2 col-3">
                                                <span className="unity-font">{item.floor}</span>
                                            </div>
                                            <div className="col-lg-2 col-3 d-none d-lg-block" style={{textAlign:'center'}}>
                                                <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Ambientes</span>
                                            </div>
                                            <div className="col-lg-3 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Sup. cubierta </span>
                                            </div>
                                            <div className="col-lg-2 col-3" style={{textAlign:"center"}}>
                                                <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Sup. Total </span>
                                            </div>
                                            <div className="col-lg-2 col-4" style={{textAlign:"center"}}>
                                                <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Precio </span>
                                            </div>
                                            <div className="col-lg-1 col-2" style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                                            <i className={'icon-arrow-right'}></i>
                                        </div>
                                        </div>
                                        <div className="close-deployable collapse pb-3" id={"collapseExample"+index}>
                                            {item.unities.sort((a, b) => a.address.split(" ")[1]?.localeCompare(b.address.split(" ")[1])).map((element,index) => (                    
                                                (element.status === 2 ?
                                                <>
                                                    <a href={"/propiedad/" + element.operation.toLowerCase() + "/" + element.id} key={index} target={"_blank"}>
                                                        <div className={"unity-row unit row " + (element.status !== 2 ? 'disabled' : '')} >
                                                            <div className="col-lg-2 col-3">
                                                                <span className={"unity-yellow-font montserrat-regular proyect-color"}>{element.address}</span>
                                                            </div>
                                                            <div className="col-lg-2 col-3 d-none d-lg-block" style={{textAlign:'center'}}>
                                                                <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}>{element.rooms}<sup></sup></span>
                                                            </div>
                                                            <div className="col-lg-3 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                                <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface} m<sup>2</sup></span>
                                                            </div>
                                                            <div className="col-lg-2 col-3" style={{textAlign:"center"}}>
                                                                <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface_total} m<sup>2</sup></span>
                                                            </div>
                                                            <div className="col-lg-2 col-4" style={{textAlign:"center"}}>
                                                                <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}> {element.currency} {element.price > 0 ? new Intl.NumberFormat("de-DE").format(element.price) : element.price}</span>
                                                            </div>
                                                            <div className="col-2 col-lg-1 round" style={{minWidth:'5rem',textAlign:"end"}}><i className="icon-arrow-right" ></i></div>
                                                        </div>
                                                    </a>
                                                </>
                                                : <>
                                                    <Link to={"/propiedad/" + element.operation.toLowerCase() + "/" + element.id}>
                                                        <div className={"unity-row unit row disabled"} >
                                                            <div className="col-lg-6 col-3">
                                                                <span className={"unity-yellow-font proyect-color"}>{element.address}  </span>
                                                            </div>
                                                            <div className="col-lg-2 col-3" style={{textAlign:'center',transform:"rotate(0deg)"}}>
                                                                <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}>{element.rooms}<sup></sup></span>
                                                            </div>
                                                            <div className="col-lg-3 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                                <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface} m<sup>2</sup></span>
                                                            </div>
                                                            <div className="col-lg-2 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                                <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface_total} m<sup>2</sup></span>
                                                            </div>
                                                            <div className="col-lg-3 col-4" style={{textAlign:"center"}}>
                                                                <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}> {element.currency} {new Intl.NumberFormat("de-DE").format(element.price)}</span>
                                                            </div>
                                                            {/* <div className="col-2 col-lg-1 round"><Link to="" className="icon-arrow-right"></Link></div> */}
                                                        </div>
                                                    </Link>
                                                  </> )
                                            ))}
                                        </div>
                                        {/* <div class="row text-center justify-content-between">
                                            {unities.map((unity,index) => (
                                                <div class="col-12">
                                                    <a style={{display:"contents"}} href="">
                                                        <div class="unit">
                                                            <div class="row text-center no-gutters justify-content-lg-between align-items-center">
                                                                <p class="col-lg-2 col-4 ps-lg-4 ps-lg-auto red text-start">{getRealAddress(unity)}</p>
                                                                <p class="col-lg-1 col-3">{getEnvironment(unity)}</p>
                                                                <div class="col-lg-8 col-3 px-lg-0">
                                                                    <div class="row align-items-center">
                                                                        <p class="text-center col-lg-2 d-none d-lg-inline">{getRooms(unity)}</p>
                                                                        <p class="text-center col-lg-2 d-none d-lg-inline">{getSurfaces(unity).roofed}</p>
                                                                        <p class="text-center col-lg-2 d-none d-lg-inline">{getSurfaces(unity).total_surface}</p>
                                                                        <p class="text-center col-lg-2 d-none d-lg-inline">{hasGarage(unity) ? 'Si' : 'No'}</p>
                                                                        <p class="text-center col-lg-2 ">{getPrices(unity)[0].currency + " " + Intl.NumberFormat("de-DE").format(getPrices(unity)[0].price)}</p>
                                                                        <p class="text-center col-lg-2 ">-</p>
                                                                        <p class="text-center col-lg-2 d-none d-lg-inline"><?php if(isset($prop_data['price']['Alquiler']) ){ echo $prop_data['price']['Alquiler']; }else{ echo '-' ;} ?></p>
                                                                    </div>
                                                                </div>
                                                                <p class="round col-lg-1 col-2"><Link to={makeLink(unity)} className="icon-arrow-right"></Link></p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div> */}
                                    </div>
                                )) : '' }
                            <div className={"col-10 m-auto text-center" + (group.length === 0 ? '  ' : " d-none") }>
                                <p className="disabled-unities text-center" style={{fontSize:"1rem",margin: "0rem 0", maxWidth: ""}}>No hay resultados para tu busqueda.</p>
                            </div>
                        </div>
                    </div>  
                </> :'')}
                <div class="row contacto-main pb-5">
                    <div class="col-lg-5 col-12 pt-5 pt-lg-0">
                        <h2 class="my-4 mt-lg-0">Consultanos por este emprendimiento </h2>
                        <div class="content-contact">
                            <a class="mailto" href={"mailto:" + realEstate.email}><h5>{realEstate.email}</h5></a>
                            <div class="row mb-lg-3">
                                <div class="col-lg-8">
                                    <a class="mailto mb-4 d-block hover-service-link" target="_blank" href={"mailto:" + realEstate.global_email}><h5>{realEstate.global_email}</h5></a>
                                    <div class="whatsapp my-3">
                                        <h5 className="mb-3">Whatsapp</h5>
                                        {realEstate.branch_office.length > 0 &&
                                            realEstate.branch_office.map((branch,index) => (
                                            <div class="d-block flex-wrap mt-3 mt-lg-0" key={index}>
                                                { branch.contact.whatsapp && <p className="d-none d-lg-flex flex-wrap"><div className="fixed-width"> {branch.name} </div> <a className='ms-4' href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+549" + branch.contact.whatsapp} target="_blank">{branch.contact.whatsapp}</a></p>}  
                                                { branch.contact.whatsapp && <p className="d-flex d-lg-none flex-wrap"><div className="fixed-width"> {branch.name} </div> <a className='ms-4' href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+549" + branch.contact.whatsapp} target="_blank">{branch.contact.whatsapp}</a></p>}  
                                            </div>))} 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-12 mt-4 mt-lg-0">
                        <Formulario development={development} from={'development'} />
                        <div id="alert-message"></div>
                    </div>
                </div>
            </div>
        </section>
    ):
    <>
        <Loading absolute={true}/>
    </>
}

export default connect(state => ({
    unities: state.developments.unities,
    development: state.developments.development,
    loading: state.developments.loading,
}),null)(Units);