import React,{useRef} from 'react'
import { StaticImage } from "gatsby-plugin-image"
import SETTINGS from "../../images/svg/settings-light.svg";
import BUILDING from "../../images/svg/building-light.svg";
import LOCATION from "../../images/svg/location-light.svg";
import RELOJ from "../../images/svg/reloj-light.svg";
import STAR from "../../images/svg/star-light.svg";
import Map from '../map'
import OwlCarousel from 'react-owl-carousel3';
import { connect } from 'react-redux'
import Breadcrumbs from "../breadcrumbs";
import SimpleReactLightbox, { SRLWrapper, useLightbox }from 'simple-react-lightbox'
// import { graphql, useStaticQuery } from 'gatsby';

//Helpers
import {getCountUnities,getMinAndMaxPrice} from '../../helpers/helper.unities'
import { getLocation,getStatus, getContructionDate, getName, getTitle, getFakeAddress } from '../../helpers/helper.developments';
import {getPhotos,getDescription,getQuality } from '../../helpers/helper.rendering'
import {getTour,getLastVideo, getTags,getOperations,getType,getFakeAddres} from '../../helpers/helper.properties'
// import {
//     getFakeAddres,
//     getLocation,
//     getRooms,
//     getPrices,
//     getOperations,
//     getType,
//     makeLink,
//     makeTextAmenities,
//     makeTextSurfaces,
//     makeTextTags,
//     getTour,
//     getRealAddress}
//     from '../../helpers/helper.properties'

import Loading from '../../components/Loading'
import { graphql, useStaticQuery } from 'gatsby';
import FilesPro from './files-pro';


const About = ({development,unities,loading,dispatch}) => {
    const slider = useRef()

    const { realEstate } = useStaticQuery(graphql`
      query {
            realEstate {
                name
              sections {
                    home {
                        properties{
                            title
                            subtitle
                        }
                    }
                }
            }
        
    }`)

    const options = {
        settings: {
            overlayColor: "rgba(0, 0, 0, 0.9)",
            autoplaySpeed: 1500,
            transitionSpeed: 900,
            disableWheelControls:true
        },
        buttons: {
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            iconColor: "rgba(255,255,255, 1)",
        },
        caption: {
            captionColor: "#000",
            captionFontFamily: "Raleway, sans-serif",
            captionFontWeight: "300",
            captionTextTransform: "uppercase",
        }
        };
                                    

    return !loading ? (
    <>
        <div class="about-top">
            <div class="container-fluid">
                <div class="d-lg-flex align-items-lg-start justify-content-lg-between">
                    <div class="column">
                        <div class="row with-border py-2">
                            <div class="col-12 pe-lg-5 py-4 py-lg-0 d-flex align-items-start justify-content-center flex-column">
                                {unities.length === undefined ? ('') :
                                    <> 
                                        <div class="item mb-lg-4 d-flex align-items-center flex-column d-lg-block m-auto m-lg-0 mb-3">
                                            <p class="mb-lg-2 mr-4 price-p">Desde</p>
                                            <h4>{"USD " + Intl.NumberFormat("de-DE").format(getMinAndMaxPrice(unities).min)}</h4>
                                        </div>
                                        <div class="item d-flex align-items-center flex-column d-lg-block m-auto m-lg-0">
                                            <p class="mr-4 mb-lg-2 price-p">Hasta</p>
                                            <h4>{"USD " + (Intl.NumberFormat("de-DE").format(getMinAndMaxPrice(unities).max) || '??')}</h4>
                                        </div>                                    
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="column py-4 py-lg-3 d-flex d-lg-block justify-content-start">
                        <i className="icon-building-light pe-3"></i>
                        <div class="info">
                            <h3 class="mb-1 mb-lg-3">UNIDADES</h3>
                            <p>{getCountUnities(unities)}</p>
                        </div>                    
                    </div>
                    <div class="column pb-4 pt-5 py-lg-3 d-flex d-lg-block justify-content-start">
                        <i className="icon-settings-light pe-3"></i>
                        <div class="info">
                            <h3 class="mb-1 mb-lg-3">ESTADO</h3>
                            <p>{getStatus(development)}</p>
                        </div>                    
                    </div>
                    <div class="column py-4 py-lg-3 d-flex d-lg-block justify-content-start">
                        <i className="icon-location-light pe-3"></i>
                        <div class="info">
                            <h3 class="mb-1 mb-lg-3">UBICACIÓN</h3>
                            <p>{getLocation(development,true)[0]} <br /> {getLocation(development,true)[1]}</p>
                        </div>                    
                    </div>
                    <div class="column py-4 py-lg-3 d-flex d-lg-block justify-content-start">
                        <i className="icon-reloj-light pe-3"></i>
                        <div class="info">
                            <h3 class="mb-1 mb-lg-3">ENTREGA</h3>
                            <p>{getContructionDate(development)}</p>
                        </div>                    
                    </div>
                    <div class="column pt-4 pb-5 py-lg-3 d-flex d-lg-block justify-content-start">
                        <i className="icon-star-light pe-3"></i>
                        <div class="info">
                            <h3 class="mb-1 mb-lg-3">CONCEPTO</h3>
                            <p>Con materiales de la mejor calidad, nuestros proyectos son llevados a cabo para garantizar la durabilidad y asegurar tu inversión.</p>
                        </div>                    
                    </div>
                </div>           
            </div>
        </div>
        <section class="ficha-about dev px-lg-5 pt-1">
            <div className="container-fluid">
                <div class="ubicacion-main">
                    <div class="row intro py-5 mt-lg-5">
                        <div className="col-12">
                            {
                                development ?
                                <Breadcrumbs
                                    props={[
                                        { name: realEstate.name, route: "/", location: "" },
                                        { name: "Emprendimientos", route:   "/emprendimientos/", location: ""},
                                        { name: getFakeAddres(development) , route:   "", location: ""},
                                    ]}
                                    />
                                    :''
                            }
                        </div>
                        <div class="col-12">
                            <h4 class="mb-5">{realEstate.sections.home.properties.subtitle}</h4>
                            <p class="description-ficha">
                                {/* { (development && development?.description) 
                                ? development?.description?.replaceAll("&nbsp;","") : null } */}
                                {getDescription(development,999999).map((description,index) => (
                                    <>
                                        {description}
                                        <br />
                                    </>))}
                            </p>
                        </div>
                        <div class={"col-12 mt-5 " + (getTags(development).length > 0 ? '' : 'd-none')}>
                            <h4 class="mb-5 mt-5 mt-lg-auto">Detalles</h4>
                            <div class="row no-gutters amenities">
                                {development && getTags(development).map((tag,index) => (
                                    <div class="col-6 col-lg-3 mb-3 item" key={index}>
                                        <i class="icon-checked"></i>
                                        <p>{tag.name}</p>
                                    </div>))}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="video" class={"row global-view d-none " + (getLastVideo(development) === undefined ? 'd-none' : 'd-none')}>
                    <div class="col-12">
                        <h4 class="mb-5">{getLastVideo(development)?.title}</h4>
                    </div>
                    <div class="col-12">
                        <div class="vista">
                            <iframe allowfullscreen src={getLastVideo(development) ? getLastVideo(development).player_url : ''} frameborder="0"></iframe>
                        </div>
                    </div>
                </div>
                <div id="tour360" class={"row global-view d-none " + (getTour(development) === undefined ? 'd-none' : 'd-none')}>
                    <div class="col-12">
                        <h4 class="mb-5">{getTour(development)?.title}</h4>
                    </div>
                    <div class="col-12">
                        <div class="vista">
                            <iframe allowfullscreen src={getTour(development) ? getTour(development).player_url : ''} frameborder="0"></iframe>
                        </div>
                    </div>
                </div>
                { development?.files ?
                        <div className="col-12">
                            <FilesPro files={development.files} />
                        </div> : null }
                <div class="ubicacion-main">
                    <div class={"row " + (development?.files ? "" : "intro")}>
                        <div class="col-12">
                            <h4 class={"mb-5 " + (development?.files ? "mt-4" : "")}>Ubicación</h4>
                        </div>
                        {/* <div class="col-lg-10">
                            <p>
                            { getFakeAddress(development) + ", " + getLocation(development,true)[0]}<br />
                            {getLocation(development,true)[1]}
                            </p>
                        </div> */}
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <Map coordenadas={{ geo_lat: development.geo_lat, geo_long: development.geo_long }} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    ):
    <>
      <Loading absolute={true}/>
    </>
}

export default connect(state => ({
    development: state.developments.development,
    loading: state.developments.loading,
    unities: state.developments.unities,
}),null)(About);